<template>
    
        <div class=" p-1 content-details"> 
            <div class="row" style="margin-top:10px;">
        
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <table class="table table-bordered" cellspacing="0" width="100%">
                        <thead>
                            <tr style="font-size: 16px">
                                <th v-if="type == 'agence'" style="text-align: center">Agence</th>
                                <th style="text-align: center">Date</th>
                                <th v-if="type == 'agence'" style="text-align: center">Mois</th>
                                <th v-if="type == 'agence'" style="text-align: center">Utilisateur</th>
                                <th style="text-align: center">heure d'arrivé</th>
                                <th style="text-align: center">retard (minutes)</th>
                            </tr>
                        </thead>
                        <tbody class="font-weight-normal">
                            <tr v-for="val in datatable" :key="val.id">
                                <td v-if="type == 'agence'" class="font-weight-normal text-center">{{ val.nom }}</td> 
                                <td class="font-weight-normal text-center">{{ val.jour }}</td> 
                                <td v-if="type == 'agence'" class="font-weight-normal text-center">{{ val.mois_texte }}</td> 
                                <td v-if="type == 'agence'" class="font-weight-normal text-center">{{ val.name }}</td> 
                                <td class="font-weight-normal text-center">{{ val.date }}</td>
                                <td v-if="val.minute <5" style="background-color: #6aa84f;" class="font-weight-normal text-center">{{val.minute}}</td>
                                <td v-else-if="val.minute <15" style="background-color: #f6b26b;" class="font-weight-normal text-center">{{val.minute}}</td>
                                <td v-else style="background-color: #e06666;" class="font-weight-normal text-center">{{val.minute}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr style="font-size: 16px">
                                <th v-if="type == 'agence'" style="text-align: center">Agence</th>
                                <th style="text-align: center">Date</th>
                                <th v-if="type == 'agence'" style="text-align: center">Mois</th>
                                <th v-if="type == 'agence'" style="text-align: center">Utilisateur</th>
                                <th style="text-align: center">heure d'arrivé</th>
                                <th style="text-align: center">retard (minutes)</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
</template>

<script>

    export default {


        props : ['datatable','type'],
        data () {
            return {

            }
        },
        watch : {
            datatable: function () {
                console.log(this.datatable);
            }
        }
        
    }
</script>