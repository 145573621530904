<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="Utilisateur" name="first">
        <UtilisateurPresence  v-bind:usersOption="usersOption" v-bind:userSelect="userSelect" />
    </el-tab-pane>
    <el-tab-pane label="Global Agences" name="second" v-if="this.permissions == 'admin' || this.permissions == 'equipe'">
        <GbAgencePresence v-bind:agencesOption="agencesOption" v-bind:agenceSelect="agenceSelect" />
    </el-tab-pane>
    <!-- <el-tab-pane label="Global Utilisateurs" name="third">
        <GbutilisateurPresence/>
    </el-tab-pane> -->
  </el-tabs>
</template>
<script>

    // Utilities
    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';

    import UtilisateurPresence from './UtilisateurPresenceComponent.vue';
    import GbAgencePresence from './GbAgencePresenceComponent.vue';
    import GbutilisateurPresence from './GbutilisateurPresenceComponent.vue';

    export default {

        components: {
            UtilisateurPresence,
            GbAgencePresence,
            GbutilisateurPresence
        },

        data() {
        return {
            activeName: 'first',
            agencesOption: [],
            agenceSelect: [],
            usersOption: [],
            userSelect: [],
            permissions: [],
        };
        },
        methods: {

            
            permission() {

                this.permissions =  JSON.parse(localStorage.getItem('menu'));
                var statistique = this.permissions.menu.menu;

                if(statistique.includes('statistique')){

                    this.permissions =  this.permissions.statistique.presence;

                    if(this.permissions.includes('admin')){
                        this.permissions = 'admin';
                    }
                    else if(this.permissions.includes('equipe')){
                        this.permissions = 'equipe';
                    }
                    else if(this.permissions.includes('unique')){
                        this.permissions = 'unique';
                    }
                }

                
                console.log(this.permissions);
            },

            async loadFilter()
            {
                NProgress.start();

                try {

                    const response = await axios.get('v1/xperf/report/filter');
                    var res_users = response.data;
                    var agences =  JSON.parse(localStorage.getItem('site'));
                    
                    var users = [];

                    var array_users = [];
                    var array_agences = [];


                    agences.forEach(agence => {
                    
                        if (!users.includes(agence.id)) {
                            users.push(agence.id);
                            array_agences.push({
                                label: agence.nom,
                                value: agence.id
                            });
                        }
                    });


                    res_users.forEach(element => {
                    
                        if (!users.includes(element.id)) {
                            users.push(element.id);
                            array_users.push({
                                label: element.name,
                                value: element.id
                            });
                        }
                    });

                    if(array_agences.length >1){
                       array_agences.unshift({
                                label: 'Tous',
                                value: 'Tous'
                        }); 
                    }

                    this.usersOption = array_users;
                    this.userSelect = this.usersOption[0];

                    this.agencesOption = array_agences;
                    this.agenceSelect = this.agencesOption[0];


                } catch (error) {
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();

            },

        },

        created () {
            this.permission();
            this.loadFilter();
        }
    };
</script>