<template>
    <el-tabs type="border-card" class="m-2" style="height:90vh; overflow: scroll;">
        <el-tab-pane label="Présence">
            <MainPresence />
        </el-tab-pane>
        <el-tab-pane label="Réalisations"><el-empty description="Pas de données pour la période"></el-empty></el-tab-pane>
        <el-tab-pane label="Inventaires"><el-empty description="Pas de données pour la période"></el-empty></el-tab-pane>
        <el-tab-pane label="Incidents"><el-empty description="Pas de données pour la période"></el-empty></el-tab-pane>
    </el-tabs>
</template>
<script>

    // Utilities
    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';
    import MainPresence from '../components/apps/statistiqueComponents/MainPresenceComponent.vue';



    export default {
        
        components: {
            MainPresence
        },

        data() {
            return {
                permissions : sessionStorage.getItem('permissions'),
            }
        },

        mounted () {
            // console.log(this.permissions['DIR']);
        }
    }
</script>