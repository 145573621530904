<template>
    <div class="row mr-5 p-2">
        <apexchart height ="350px" type="bar" :options="options" :series="series"></apexchart>
    </div>
</template>
<script>

    import VueApexCharts from 'vue-apexcharts';

    export default {

        components: {
            'apexchart': VueApexCharts
        },

        props: ['data1','data2'],

        data () {
            
            return {

                options: {
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                borderRadius: 5
        
                            },
                        },
                        chart: {
                            height: 400,
                            toolbar: {
                                show: true,
                                tools: {
                                    download: true,
                                    selection: false,
                                    zoom: false,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false
                                },
                                export: {
                                    csv: {
                                        filename: "Analyse_Taux_de_retard_"+new Date().toDateString(),
                                        columnDelimiter: ',',
                                        headerCategory: 'category',
                                        headerValue: 'value',
                                        dateFormatter(timestamp) {
                                            return new Date(timestamp).toDateString()
                                        }
                                    },
                                    svg: {
                                        filename: "Analyse_Taux_de_retard_"+new Date().toDateString(),
                                    },
                                    png: {
                                        filename: "Analyse_Taux_de_retard_"+new Date().toDateString(),
                                    }
                                },
                            }
                        },
                        xaxis: {
                            categories: [],
                        },
                        yaxis: [{

                            seriesName: 'Durée',
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#9E9E9E'
                            },
                            title: {
                                text: "Durée en minutes",
                                style: {
                                    color: '#9E9E9E',
                                }
                            },
                            tooltip: {
                                enabled: true
                            }
                        },
                        ],
                        tooltip: {
                            fixed: {
                                enabled: true,
                                offsetY: 30,
                                offsetX: 60
                            },
                        },
                        legend: {
                            horizontalAlign: 'center',
                            offsetX: 40
                        },
                        noData: {
                            text: "Veuillez vérifier les données",
                            align: "center",
                            verticalAlign: "middle",
                        },
                        markers: {
                            size: 5,
                            colors: '#9E9E9E',
                            strokeColors: '#feb830',
                            fillOpacity: 0.6,
                        },
                        bar: {
                            dataLabels: {
                            // position: 'top'
                            }
                        },
                        dataLabels: {
                            // align: 'top',
                            enabled: true,
                            style: {
                                colors: ['#333']
                            },
                        },
                        // datalabels: {
                        //     anchor: 'end',
                        //     align: 'top',
                        //     color: '#000',
                        // },
                        
                },
                series: [{
                    name: 'Durée',
                    type: 'column',
                    data: []
                }]
            }
        },

        watch: {
            data1 : function() {
                this.options = {...this.options, ...{
                    xaxis: {
                        categories: this.data1,
                    }
                }}
                this.series[0].data = this.data2; 
            },
        },

        mounted() {
            this.series[0].data = this.data2;
            this.options = {...this.options, ...{
                    xaxis: {
                        categories: this.data1,
                    }
            }}
        }
    }
</script>